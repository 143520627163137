<template>
  <form class="timecard-update-form" @submit.prevent="register">
    <button
      class="btn btn-secondary mb-3 d-flex align-items-center"
      onclick="history.back();"
      type="button"
    >
      <span class="material-icons text-white me-1">arrow_back_ios</span
      >キャンセル
    </button>
    <h1 class="mb-3">勤怠管理&emsp;一括登録</h1>
    <bs-alert
      message="一括登録期間中に承認された申請がある場合、申請に上書きして登録されます。
      休日出勤に一括登録する場合は、それに関連する日の勤怠がクリアされます。一括登録後には関連日も修正してください。"
      color="danger"
    />
    <bs-alert
      v-if="errorMessage"
      :message="errorMessage"
      color="danger"
      closable
      @close="errorMessage = ''"
    />
    <div class="row">
      <div class="col-md-6">
        <label>開始日</label>
        <input
          type="date"
          class="form-control"
          v-model="form.start_date"
          :class="{ 'is-invalid': !!errors.start_date }"
        />
        <div v-if="errors.start_date" class="invalid-feedback d-block">
          {{ errors.start_date[0] }}
        </div>
      </div>
      <div class="col-md-6">
        <label>終了日</label>
        <input
          type="date"
          class="form-control"
          v-model="form.end_date"
          :class="{ 'is-invalid': !!errors.end_date }"
        />
        <div v-if="errors.end_date" class="invalid-feedback d-block">
          {{ errors.end_date[0] }}
        </div>
      </div>
    </div>
    <h2 class="mb-3">勤怠種別</h2>
    <div class="row">
      <div class="btn-group col-md-12">
        <div
          class="btn-group col-md-1"
          role="group"
          aria-label="kind"
          v-for="(value, key) in kinds"
          :key="key"
        >
          <input
            type="radio"
            class="btn-check"
            :class="{ 'is-invalid': !!errors.kind }"
            name="kind"
            :id="`kind-${key}`"
            :value="key"
            v-model="form.kind"
          />
          <label class="btn btn-outline-success py-2" :for="`kind-${key}`">
            {{ value }}
          </label>
        </div>
      </div>
      <div v-if="errors.kind" class="invalid-feedback d-block">
        {{ errors.kind[0] }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-3">
        <label>勤怠変更理由</label>
        <textarea
          cols="2"
          v-model="form.reasons_for_change"
          :class="{
            'is-invalid': !!errors.reasons_for_change,
          }"
        >
        </textarea>
        <div v-if="errors.reasons_for_change" class="invalid-feedback d-block">
          {{ errors.reasons_for_change[0] }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label>コメント</label>
        <textarea
          cols="2"
          v-model="form.comment"
          :class="{ 'is-invalid': !!errors.comment }"
        >
        </textarea>
        <div v-if="errors.comment" class="invalid-feedback d-block">
          {{ errors.comment[0] }}
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-around">
      <div>
        <button
          type="button"
          class="btn btn-secondary"
          @click="
            $router.replace(`/timecard?employee=${this.$route.params.id}`)
          "
        >
          キャンセル
        </button>
      </div>
      <button type="submit" class="btn btn-primary">&emsp;登録&emsp;</button>
    </div>
  </form>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";
import BsAlert from "@/components/Alert.vue";

export default {
  name: "BulhUpdateFormView",
  components: { BsAlert },
  data: () => ({
    kinds: [],
    reasons: [],
    reasonsForOvertime: [],
    errors: {},
    errorMessage: "",
    employee: {},
    timecard: {},
    work_start_minutes: "",
    work_finish_minutes: "",
    today: "",
    form: {
      kind: "",
      start_date: "",
      end_date: "",
      comment: "",
      reasons_for_change: "",
    },
    kind: {
      work_on_weekdays: 1,
      work_on_holiday: 2,
      public_holiday: 3,
      paid_holiday: 4,
      special_holiday: 5,
      absence: 6,
      substitute_holiday: 7,
      direct_return: 8,
      business_trip: 9,
    },
  }),
  mounted() {
    this.$emit("loading", true);
    this.today = this.formatDate(new Date());

    client
      .get(`/api/timecard/bulk_update/${this.$route.params.id}`)
      .then((res) => {
        this.$emit("loading", false);
        this.kinds = res.data.kinds;
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
          this.errors = { ...error.response.data };
          this.errorMessage = error.response.data.work_minutes;
        }
        this.$emit("loading", false);
      });
  },
  methods: {
    register() {
      this.$emit("loading", true);
      this.errors = {};

      client
        .put(`/api/timecard/bulk_update/${this.$route.params.id}`, this.form)
        .then((res) => {
          this.$emit("loading", false);
          this.$router.replace(
            `/timecard?updated=${res.data.message}&employee=${this.$route.params.id}`
          );
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
            this.errorMessage = error.response.data.work_minutes;
          }
          this.$emit("loading", false);
        });
    },
    formatDate(dt) {
      const y = dt.getFullYear();
      const m = ("00" + (dt.getMonth() + 1)).slice(-2);
      const d = ("00" + dt.getDate()).slice(-2);
      return y + "-" + m + "-" + d;
    },
  },
};
</script>

<style scoped lang="scss">
.row > * {
  margin-bottom: 1rem;
}
h3,
h4 {
  text-align: center;
}
textarea:not(.is-invalid) {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
}
textarea.is-invalid {
  width: 100%;
  border: 1px solid #dc3545ff;
  border-radius: 6px;
}
textarea:focus:not(.is-invalid) {
  border: 1px solid rgba(39, 80, 140, 0.81);
  outline: 5px solid rgba(56, 150, 245, 0.3);
}
textarea:focus.is-invalid {
  border: 1px solid rgb(220, 53, 69);
  outline: 5px solid rgba(220, 53, 69, 0.3);
}
textarea:disabled {
  background-color: #e9ecef;
}
.btn-outline-success {
  background-color: #eee;
  color: #999;
  border: 1px solid #999;
}
.btn-outline-success:hover:not:focus {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.btn-outline-success:active {
  background-color: #1a8754;
  color: #000;
  border: 1px solid #000;
}
</style>
