<template>
  <button
    class="btn btn-secondary mb-3 d-flex align-items-center"
    @click="$router.push('/request_list')"
  >
    <span class="material-icons text-white me-1">arrow_back_ios</span>キャンセル
  </button>
  <h1 class="mb-3">申請&emsp;詳細</h1>
  <div class="content">
    <table id="summary" class="w-100 mb-3">
      <tr>
        <th>申請日</th>
        <td colspan="3">{{ request.request_date }}</td>
      </tr>
      <tr>
        <th>申請種別</th>
        <td>{{ request.kind_text }}</td>
        <th>ステータス</th>
        <td>{{ request.status_text }}</td>
      </tr>
      <tr>
        <th>申請者</th>
        <td colspan="3">
          {{ request.employee.name }}({{ request.employee.identification }})
        </td>
      </tr>
      <tr>
        <th>対象日</th>
        <td>{{ request.start_date }}</td>
        <th>日数</th>
        <td>{{ request.days }}日</td>
      </tr>
      <tr v-if="request.related_date">
        <th>関連日</th>
        <td colspan="3">{{ request.related_date }}</td>
      </tr>
      <tr v-if="request.kind == 5">
        <th>特別休暇種別</th>
        <td>{{ request.special_text }}</td>
        <th v-if="request.special_kind != 3">続柄</th>
        <td v-if="request.special_kind != 3">{{ request.relationship }}</td>
        <th v-if="request.special_kind == 3">特別休暇その他</th>
        <td v-if="request.special_kind == 3">{{ request.special_reason }}</td>
      </tr>
      <tr
        v-if="
          (request.kind == 8 || request.kind == 2) &&
          (request.work_start_at || request.work_end_at)
        "
      >
        <th>始業時間</th>
        <td>{{ request.work_start_at }}</td>
        <th>就業時間</th>
        <td>{{ request.work_end_at }}</td>
      </tr>
      <tr v-if="request.kind == 8 && (request.go_out_at || request.return_at)">
        <th>外出時間</th>
        <td>{{ request.go_out_at }}</td>
        <th>戻り時間</th>
        <td>{{ request.return_at }}</td>
      </tr>
      <tr>
        <th>申請理由</th>
        <td colspan="3">{{ request.reason }}</td>
      </tr>
      <tr v-if="request.action_text || request.break_minutes">
        <th>休憩時間</th>
        <td>{{ request.break_minutes }}</td>
        <th>行動種別</th>
        <td>{{ request.action_text }}</td>
      </tr>
      <tr v-if="request.authorizer">
        <th>承認者</th>
        <td colspan="3">
          {{ request.authorizer.name }}({{ request.authorizer.identification }})
        </td>
      </tr>
      <tr v-if="request.authorizer || request.comment">
        <th>承認者コメント</th>
        <td colspan="3">{{ request.comment || "" }}</td>
      </tr>
      <tr v-if="request.authorizer">
        <th>更新日</th>
        <td colspan="3">{{ request.updated_date }}</td>
      </tr>
    </table>
  </div>
  <button
    v-if="request.status == 1"
    type="button"
    class="btn btn-danger ms-4"
    @click="destroy"
  >
    &emsp;取り消し&emsp;
  </button>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";

export default {
  name: "DetailView",
  data: () => ({
    errors: {},
    errorMessage: "",
    employee: {},
    request: {
      employee: {},
      authorizer: {},
    },
    kind: {
      not_enrolled: 0,
      work_on_weekdays: 1,
      work_on_holiday: 2,
      public_holiday: 3,
      paid_holiday: 4,
      special_holiday: 5,
      absence: 6,
      substitute_holiday: 7,
      direct_return: 8,
      business_trip: 9,
    },
  }),
  mounted() {
    this.$emit("loading", true);

    client
      .get("/api/request/request_detail/" + this.$route.params.id)
      .then((res) => {
        this.request = res.data;
        this.$emit("loading", false);
      })
      .catch((error) => {
        console.error(error);
        this.$emit("loading", false);
        this.$router.push(`/request_list?error=${error.response.data.detail}`);
      });
  },
  methods: {
    formatSeconds(minutes) {
      let hour = Math.floor(minutes / 60);
      const minute = ("00" + (minutes % 60)).slice(-2);
      return hour + ":" + minute;
    },
    destroy() {
      if (
        !confirm(
          "この操作は取り消しできません。\n本当に削除してよろしいですか？"
        )
      ) {
        return;
      }
      this.$emit("loading", true);
      client
        .delete("/api/request/request/" + this.$route.params.id)
        .then(() => {
          this.$emit("loading", false);
          this.$router.push("/request_list");
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
          let message = "取り消しできませんでした。";
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            message = error.response.data.error;
          }
          this.$router.push("/request_list");
          alert(message);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.row > * {
  margin-bottom: 1rem;
}
h3,
h4 {
  text-align: center;
}
.content {
  width: 650px;
  margin: auto;
}
#summary {
  table-layout: fixed;
}
#summary th {
  text-align: center;
  border: solid 1px #333;
  background-color: #ccc;
}
#summary td {
  text-align: right;
  padding: 0.5rem;
  border: solid 1px #333;
}
</style>
