<template>
  <nav class="navbar navbar-expand-lg navbar-light shadow-sm">
    <div class="container-fluid px-5">
      <a class="navbar-brand" @click="$router.push('/')">
        <img :src="logoUrl" alt="" />
        <span class="version">(version: {{ packageJson.version }})</span>
      </a>

      <div
        v-if="$store.getters['user/isLoggedIn']"
        class="d-flex align-items-center"
      >
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-link"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            aria-expanded="false"
          >
            <span class="material-icons">help_outline</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-lg-end">
            <li v-if="$store.getters['user/user'].access_right == 1">
              <button
                type="button"
                class="dropdown-item"
                @click="downloadManual(1)"
              >
                管理者向けマニュアル
              </button>
            </li>
            <li v-if="$store.getters['user/user'].access_right != 3">
              <button
                type="button"
                class="dropdown-item"
                @click="downloadManual(2)"
              >
                承認者向けマニュアル
              </button>
            </li>
            <li>
              <button
                type="button"
                class="dropdown-item"
                @click="downloadManual(3)"
              >
                ユーザー向けマニュアル
              </button>
            </li>
            <li v-if="$store.getters['user/user'].access_right == 1">
              <button
                type="button"
                class="dropdown-item"
                @click="downloadInstaller()"
              >
                インストーラー(最新版)
              </button>
            </li>
          </ul>
        </div>
        <span>{{ $store.getters["user/user"].name }}</span>
        <button class="btn btn-link ms-1" @click="logout">
          <span class="material-icons">logout</span>
        </button>
      </div>
    </div>
  </nav>
  <main class="container-fluid px-5" ref="main">
    <router-view @loading="loading = $event" />
  </main>

  <div v-if="loading" class="loading">
    <div>
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";

export default {
  data: () => ({
    loading: false,
    logoUrl: `${process.env.BASE_URL}images/header_logo.png`,
    packageJson: require("../package.json"),
  }),
  watch: {
    loading() {
      if (this.loading) {
        document.addEventListener("mousewheel", this.scroll, {
          passive: false,
        });
        document.addEventListener("touchmove", this.scroll, {
          passive: false,
        });
      } else {
        document.removeEventListener("mousewheel", this.scroll, {
          passive: false,
        });
        document.removeEventListener("touchmove", this.scroll, {
          passive: false,
        });
      }
    },
  },
  methods: {
    scroll(event) {
      event.preventDefault();
    },
    logout() {
      this.$store.dispatch("user/logout");
      this.$router.push({ path: "/login" });
    },
    downloadManual(access_right) {
      client({
        url: "/api/system/manual",
        method: "GET",
        responseType: "blob",
        params: { access_right },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const contentDisposition = response.headers["content-disposition"];
          const match = contentDisposition.match(/attachment; filename=(.*)/);
          link.setAttribute("download", decodeURI(match[1]));
          document.body.appendChild(link);
          link.click();
          this.$emit("loading", false);
        })
        .catch((error) => {
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errorMessage = error.response.data.detail;
          }
          this.$emit("loading", false);
        });
    },
    downloadInstaller() {
      client({
        url: "/api/system/export_installer",
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const contentDisposition = response.headers["content-disposition"];
          const match = contentDisposition.match(/attachment; filename=(.*)/);
          link.setAttribute("download", decodeURI(match[1]));
          document.body.appendChild(link);
          link.click();
          this.$emit("loading", false);
        })
        .catch((error) => {
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errorMessage = error.response.data.detail;
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style lang="scss">
@import "assets/css/app";

.version {
  font-size: 0.75rem;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;

  & > div {
    position: fixed;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
  }

  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
}
@media print {
  #app nav {
    display: none;
  }
  body {
    zoom: 0.75;
  }
}
</style>
